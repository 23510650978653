import React, {ChangeEvent, useState} from 'react';
import Modal from 'react-modal';
import {
    PartnerDexOption,
    PartnerSimCardOption, PartnerVendcoinModem
} from "./gen-partner";

interface Props {
    onVendcoinModemCreated(vendcoinModem: PartnerVendcoinModem): void
    isOpen: boolean
    onclose():void
}

function NewVendcoinModemModal(props: Props) {
    const [vendcoinModem, setVendcoinModem] = useState<PartnerVendcoinModem>({
        modem_configuration: {
            wim_board: false,
            region: "Australia",
        },
        dex_option: PartnerDexOption.None,
        sim_card_option: PartnerSimCardOption.AusThreeMainCarriers
    });
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
        overlay:{zIndex:1000}
    };

    const closeModal = ()=>{
        props.onclose();
    }

    const newVendcoinModem = (e:React.MouseEvent<HTMLButtonElement>)=>{
        e.preventDefault();
        e.stopPropagation();
        props.onVendcoinModemCreated(vendcoinModem)
        closeModal();
    }

    const handleDexOption = (e: ChangeEvent<HTMLSelectElement>)=>{
        setVendcoinModem({...vendcoinModem, dex_option: e.target.value as PartnerDexOption})
    }

    const handleSimOption = (e: ChangeEvent<HTMLSelectElement>)=>{
        setVendcoinModem({...vendcoinModem, sim_card_option: e.target.value as PartnerSimCardOption})
    }

    const handleWimBoard = (e: ChangeEvent<HTMLInputElement>) => {
        console.log("Checked: " + e.target.checked);
        setVendcoinModem({...vendcoinModem, modem_configuration: {...vendcoinModem.modem_configuration, wim_board: e.target.checked}})
    }

    const handleRegion = (e: ChangeEvent<HTMLInputElement>) => {
        setVendcoinModem({...vendcoinModem, modem_configuration: {...vendcoinModem.modem_configuration, region: e.target.value}})
    }


    return (
        <Modal
            isOpen={props.isOpen}
            onRequestClose={closeModal}
            style={customStyles}
            ariaHideApp={false}
        >
            <div className={"container"}>
                <h5>New Credit Card Reader Combo Configurations</h5>
                <form className="row g-3">
                    <div className="col-12">
                        <label className="form-label">Dex Options</label>
                        <select className="form-select" value={vendcoinModem.dex_option} onChange={handleDexOption}>
                            <option value={PartnerDexOption.None}>None</option>
                            <option value={PartnerDexOption.Db9}>DB9</option>
                            <option value={PartnerDexOption.AudioJack}>Audio Jack</option>
                        </select>
                    </div>
                    <div className="col-12">
                        <label className="form-label">SIM Card Options</label>
                        <select className="form-select" value={vendcoinModem.sim_card_option} onChange={handleSimOption}>
                            <option value={PartnerSimCardOption.AusThreeMainCarriers}>Roaming between AUS 3 main carries</option>
                            <option value={PartnerSimCardOption.Telstra}>Telstra</option>
                            <option value={PartnerSimCardOption.International}>International</option>
                        </select>
                    </div>
                    <div className="col-12">
                        <label className="form-label">Region</label>
                        <input type="text" className="form-control"
                               placeholder="Australia" onChange={handleRegion} value={vendcoinModem.modem_configuration?.region}/>
                    </div>
                    <div className="col-12">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" checked={vendcoinModem.modem_configuration?.wim_board} onChange={handleWimBoard}/>
                            <label className="form-check-label">
                                Wim board
                            </label>
                        </div>
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary" onClick={newVendcoinModem}>Add</button>
                    </div>
                </form>
            </div>

        </Modal>
    );
}

export default NewVendcoinModemModal;
