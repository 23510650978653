import React from 'react';
import moment from "moment";

interface Props {
    epoch?: number
}

function Datetime(props: Props) {
    if (props.epoch){
        return (
            <span>{moment.unix(props.epoch).toString()}</span>
        );
    } else {
        return (
            <span>Not Available</span>
        )
    }
}

export default Datetime;
