import React from 'react';
import {PartnerLead, PartnerNote} from "./gen-partner";
import DataTable, {ExpanderComponentProps, TableColumn} from "react-data-table-component";
import Datetime from "./datetime";

interface Props extends ExpanderComponentProps<PartnerLead> {

}

function LeadNotes(props:Props) {
    const columns:TableColumn<PartnerNote>[] = [
        {
            name: 'Created At',
            selector: (row:PartnerNote) => row.created_epoch ?? "",
            wrap: true,
            cell: row => <Datetime epoch={row.created_epoch}/>,
            sortable: true
        },
        {
            name: 'Created By',
            selector: (row:PartnerNote) => row.created_by ?? "",
        },
        {
            name: 'Content',
            selector: (row:PartnerNote) => row.content ?? "",
        },
    ];

    return (
        <div>
            <h5>Notes</h5>
            <DataTable
                columns={columns}
                data={props.data.notes ?? []}
            />
        </div>
    );
}

export default LeadNotes;
