import React, {Fragment, useEffect, useState} from 'react';
import {PartnerOrder, PartnerOrderState} from "./gen-partner";
import {getPartnerOrderServicesApi} from "./api";
import DataTable, {TableColumn} from "react-data-table-component";
import ReactPaginate from "react-paginate";
import OrderDetails from "./order_details";

interface Props {

}

function Orders(props:Props) {
    const [orders, setOrders] = useState<PartnerOrder[]>([]);
    const [page, setPage] =useState<number>(0);
    const [totalPages, setTotalPages] =useState<number>(1);

    const reloadOrders = ()=>{
        getPartnerOrderServicesApi().partnerOrderServicesListOrders({
            states:[
                PartnerOrderState.Fulfilled,
                PartnerOrderState.PendingReview,
                PartnerOrderState.PendingPayment,
                PartnerOrderState.ReadyForPickup,
                PartnerOrderState.Shipped,
                PartnerOrderState.Preparing
            ],
            page: page + 1,
            per_page: 50,
        }).then((response)=>{
            if (response.data.success){
                setOrders(response.data.orders ?? [])
                setTotalPages(response.data.total_pages ?? 1);
            } else {
                window.alert(response.data.error_message);
            }
        }).catch((e)=>{
            console.log(e)
            window.alert("Server error");
        }).finally(()=>{

        })
    }

    useEffect(()=>{
        reloadOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    const columns:TableColumn<PartnerOrder>[] = [
        {
            name: 'UUID',
            selector: (row:PartnerOrder) => row.uuid ?? "",
            cell: row => row.uuid?.slice(0, 4)
        },
        {
            name: 'State',
            selector: (row:PartnerOrder) => row.state ?? "",
            wrap: true,
        },
        {
            name: 'Client Account',
            selector: (row:PartnerOrder) => row.account_business_name ?? "",
            wrap: true,
        },
        {
            name: 'Other',
            selector: (row:PartnerOrder) => row.other ?? "",
            wrap: true,
        },
    ];

    const onPageChange = (selectedItem:{selected: number})=>{
        setPage(selectedItem.selected)
    }

    return (
        <Fragment>
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Orders</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                    </div>
                </div>
                <div>
                    <h5>Order table</h5>
                    <DataTable
                        columns={columns}
                        data={orders}
                        expandableRows={true}
                        expandableRowsComponent={OrderDetails}
                    />
                    <ReactPaginate
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        nextLabel="next >"
                        onPageChange={onPageChange}
                        pageRangeDisplayed={5}
                        pageCount={totalPages}
                        previousLabel="< previous"
                    />
                </div>
            </main>
        </Fragment>
    );
}

export default Orders;
