import React, {Fragment, useEffect, useState} from 'react';
import {PartnerClientAccount, PartnerClientAccountState} from "./gen-partner";
import {getPartnerClientLeadServicesApi} from "./api";
import DataTable, {TableColumn} from "react-data-table-component";
import ReactPaginate from "react-paginate";
import ClientAccountDetails from "./client_account_details";
import Datetime from "./datetime";
import {Link} from "react-router-dom";

interface Props {

}

function Accounts(props:Props) {
    const [clientAccount, setClientAccount] = useState<PartnerClientAccount[]>([]);
    const [page, setPage] =useState<number>(0);
    const [totalPages, setTotalPages] =useState<number>(1);

    const reloadAccount = ()=>{
        getPartnerClientLeadServicesApi().partnerClientAccountServicesListClientAccounts({
            states:[PartnerClientAccountState.Active, PartnerClientAccountState.InProgress, PartnerClientAccountState.Pending],
            page: page + 1,
            per_page: 50,
        }).then((response)=>{
            if (response.data.success){
                setClientAccount(response.data.accounts ?? [])
                setTotalPages(response.data.total_pages ?? 1);
            } else {
                window.alert(response.data.error_message);
            }
        }).catch((e)=>{
            console.log(e)
            window.alert("Server error");
        }).finally(()=>{

        })
    }

    useEffect(()=>{
        reloadAccount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    const columns:TableColumn<PartnerClientAccount>[] = [
        {
            name: 'UUID',
            selector: (row:PartnerClientAccount) => row.uuid ?? "",
            cell: row => row.uuid?.slice(0, 4)
        },
        {
            name: 'Created At',
            selector: (row:PartnerClientAccount) => row.created_epoch ?? "",
            wrap: true,
            cell: row => <Datetime epoch={row.created_epoch}/>,
            sortable: true
        },
        {
            name: 'State',
            selector: (row:PartnerClientAccount) => row.state ?? "",
            wrap: true,
        },
        {
            name: 'Business Name',
            selector: (row:PartnerClientAccount) => row.business_name ?? "",
            wrap: true,
        },
        {
            name: 'ABN(ACN)',
            selector: (row:PartnerClientAccount) => row.abn_acn ?? "",
            wrap: true,
        },
        {
            name: 'Contact Email',
            selector: (row:PartnerClientAccount) => row.contact_email ?? "",
            wrap: true,
        },
        {
            name: 'Waiting for',
            selector: (row:PartnerClientAccount) => row.waiting_for ?? "",
            wrap: true,
        },
        {
            name: "Operations",
            cell:(row:PartnerClientAccount) => <Link className={"btn btn-light"} to={`/accounts/${row.uuid}/order-placements`}>Place Order</Link>
        }
    ];

    const onPageChange = (selectedItem:{selected: number})=>{
        setPage(selectedItem.selected)
    }

    return (
        <Fragment>
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Accounts</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                    </div>
                </div>
                <div>
                    <h5>Account table</h5>
                    <DataTable
                        columns={columns}
                        data={clientAccount}
                        expandableRows={true}
                        expandableRowsComponent={ClientAccountDetails}
                    />
                    <ReactPaginate
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        nextLabel="next >"
                        onPageChange={onPageChange}
                        pageRangeDisplayed={5}
                        pageCount={totalPages}
                        previousLabel="< previous"
                    />
                </div>
            </main>
        </Fragment>
    );
}

export default Accounts;
