import React from 'react';

interface BusyIndicatorProps{
    busy:boolean,
    busyIndicator:React.ReactNode
}

function BusyIndicator(props:React.PropsWithChildren<BusyIndicatorProps>) {
    return (
        <React.Fragment>
            {props.busy && props.busyIndicator}
            {!props.busy && props.children}
        </React.Fragment>
    );
}

export default BusyIndicator;

export function LoadingSpanner(){
    return (
        <div className="spinner-grow text-primary" role="status">
            <span className="sr-only"></span>
        </div>
    )
}
