import React, {ChangeEvent, useState} from 'react';
import Modal from 'react-modal';
import {
    PartnerPpeVendingMobile,
    PartnerSimCardOption
} from "./gen-partner";

interface Props {
    onPpeVendingMobileCreated(ppeVendingMobile: PartnerPpeVendingMobile): void
    isOpen: boolean
    onclose():void
}

function NewPpeVendingMobileModal(props: Props) {
    const [ppeVendingMobile, setPpeVendingMobile] = useState<PartnerPpeVendingMobile>({
        modem_configuration: {
            wim_board: false,
            region: "Australia",
        },
        sim_card_option: PartnerSimCardOption.AusThreeMainCarriers
    });
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
        overlay:{zIndex:1000}
    };

    const closeModal = ()=>{
        props.onclose();
    }

    const newPpeVendingMobile = (e:React.MouseEvent<HTMLButtonElement>)=>{
        e.preventDefault();
        e.stopPropagation();
        props.onPpeVendingMobileCreated(ppeVendingMobile)
        closeModal();
    }

    const handleSimOption = (e: ChangeEvent<HTMLSelectElement>)=>{
        setPpeVendingMobile({...ppeVendingMobile, sim_card_option: e.target.value as PartnerSimCardOption})
    }

    const handleWimBoard = (e: ChangeEvent<HTMLInputElement>) => {
        console.log("Checked: " + e.target.checked);
        setPpeVendingMobile({...ppeVendingMobile, modem_configuration: {...ppeVendingMobile.modem_configuration, wim_board: e.target.checked}})
    }

    const handleRegion = (e: ChangeEvent<HTMLInputElement>) => {
        setPpeVendingMobile({...ppeVendingMobile, modem_configuration: {...ppeVendingMobile.modem_configuration, region: e.target.value}})
    }


    return (
        <Modal
            isOpen={props.isOpen}
            onRequestClose={closeModal}
            style={customStyles}
            ariaHideApp={false}
        >
            <div className={"container"}>
                <h5>New Credit Card Reader Combo Configurations</h5>
                <form className="row g-3">
                    <div className="col-12">
                        <label className="form-label">SIM Card Options</label>
                        <select className="form-select" value={ppeVendingMobile.sim_card_option} onChange={handleSimOption}>
                            <option value={PartnerSimCardOption.AusThreeMainCarriers}>Roaming between AUS 3 main carries</option>
                            <option value={PartnerSimCardOption.Telstra}>Telstra</option>
                            <option value={PartnerSimCardOption.International}>International</option>
                        </select>
                    </div>
                    <div className="col-12">
                        <label className="form-label">Region</label>
                        <input type="text" className="form-control"
                               placeholder="Australia" onChange={handleRegion} value={ppeVendingMobile.modem_configuration?.region}/>
                    </div>
                    <div className="col-12">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" checked={ppeVendingMobile.modem_configuration?.wim_board} onChange={handleWimBoard}/>
                            <label className="form-check-label">
                                Wim board
                            </label>
                        </div>
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary" onClick={newPpeVendingMobile}>Add</button>
                    </div>
                </form>
            </div>

        </Modal>
    );
}

export default NewPpeVendingMobileModal;
