import React, {ChangeEvent, MouseEvent,  useState} from 'react';
import {getPartnerAuthenticationServiceApi} from "./api";
import BusyIndicator, {LoadingSpanner} from './busy_indicator';
import {useNavigate, Navigate} from "react-router-dom";
import {IAuthenticationContext, withAuthentication} from "./authentication";
import logo from "./vot-logo.png";

interface Props extends IAuthenticationContext{

}

function Login(props:Props) {
    const [email, setEmail] = useState<string>("");
    const [authCode, setAuthCode] = useState<string>("");
    const [isAuthCodeRequested, setIsAuthCodeRequested] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate()

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        if (isAuthCodeRequested){
            setIsAuthCodeRequested(false)
        }
    }

    const handleAuthCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setAuthCode(event.target.value);
    }

    const getAuthCode = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (!isLoading){
            setIsLoading(true);
            getPartnerAuthenticationServiceApi().partnerAuthenticationServiceRequestAuthCode({
                email: email
            }).then((response)=>{
                if (response.data.success){
                    setIsAuthCodeRequested(true);
                } else {
                    window.alert(response.data.error_message)
                }
            }).catch(()=>{
                window.alert("Server error")
            }).finally(()=>{
                setIsLoading(false);
            })
        }
    }

    const getSecurityToken = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (!isLoading){
            setIsLoading(true);
            getPartnerAuthenticationServiceApi().partnerAuthenticationServiceVerifyAuthCode({
                email: email,
                auth_code: authCode
            }).then((response)=>{
                if (response.data.success){
                    console.log("security_token", response.data.security_token)
                    props.login(response.data.security_token ?? "", email)
                    navigate("/", {replace: true})
                } else {
                    window.alert(response.data.error_message)
                }
            }).catch(()=>{
                window.alert("Server error")
            }).finally(()=>{
                setIsLoading(false);
            })
        }
    }

    if (props.authentication.authenticated) {
        return (<Navigate to="/"/>);
    }

    return (
            <div className={"login"}>
                <main className="form-signin w-100 m-auto">
                    <form>
                        <img className="mb-4" src={logo} alt="" width="150px"/>
                            <h1 className="h3 mb-3 fw-normal">Please sign in</h1>

                            <div className="form-floating">
                                <input type="email" className="form-control" id="floatingInput"
                                       placeholder="name@example.com" value={email} onChange={handleEmailChange}/>
                                    <label htmlFor="floatingInput">Email address</label>
                            </div>

                            {
                                isAuthCodeRequested && <div className="form-floating">
                                    <input type="text" className="form-control" id="floatingPassword"
                                           placeholder="auth code" value={authCode} onChange={handleAuthCodeChange}/>
                                    <label htmlFor="floatingPassword">Auth code</label>
                                </div>
                            }

                            <br/>

                            {
                                !isAuthCodeRequested &&
                                <BusyIndicator busy={isLoading} busyIndicator={<LoadingSpanner/>}>
                                    <button className="w-100 btn btn-lg btn-primary" type="submit" onClick={getAuthCode}>Get Auth Code</button>
                                </BusyIndicator>
                            }

                            {
                                isAuthCodeRequested &&
                                <BusyIndicator busy={isLoading} busyIndicator={<LoadingSpanner/>}>
                                    <button className="w-100 btn btn-lg btn-primary" type="submit" onClick={getSecurityToken}>Login</button>
                                </BusyIndicator>
                            }
                            <p className="mt-5 mb-3 text-muted">©2022 Vending on Track </p>
                    </form>
                </main>
            </div>
    );
}

export default withAuthentication(Login);
