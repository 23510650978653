import React from 'react';
import DataTable, {ExpanderComponentProps, TableColumn} from "react-data-table-component";
import {PartnerClientAccount, PartnerNote} from "./gen-partner";
import Datetime from "./datetime";

interface Props extends ExpanderComponentProps<PartnerClientAccount> {

}

function ClientAccountDetails(props: Props) {
    const columns:TableColumn<PartnerNote>[] = [
        {
            name: 'Created At',
            selector: (row:PartnerNote) => row.created_epoch ?? "",
            wrap: true,
            cell: row => <Datetime epoch={row.created_epoch}/>,
            sortable: true
        },
        {
            name: 'Created By',
            selector: (row:PartnerNote) => row.created_by ?? "",
        },
        {
            name: 'Content',
            selector: (row:PartnerNote) => row.content ?? "",
        },
    ];

    return (
        <div>
            <div className={"container-fluid"}>
                <div className={"row"}>
                    <div className={"col-6"}>
                        <h5>Account Details</h5>
                        <table className="table">
                            <tbody>
                            <tr>
                                <th scope="row">UUID</th>
                                <td>{props.data.uuid}</td>
                            </tr>
                            <tr>
                                <th scope="row">Created At</th>
                                <td><Datetime epoch={props.data.created_epoch}/></td>
                            </tr>
                            <tr>
                                <th scope="row">Updated At</th>
                                <td><Datetime epoch={props.data.updated_epoch}/></td>
                            </tr>
                            <tr>
                                <th scope="row">State</th>
                                <td>{props.data.state}</td>
                            </tr>
                            <tr>
                                <th scope="row">Business Name</th>
                                <td>{props.data.business_name}</td>
                            </tr>
                            <tr>
                                <th scope="row">ABN(ACN)</th>
                                <td>{props.data.abn_acn}</td>
                            </tr>
                            <tr>
                                <th scope="row">Business Address</th>
                                <td>{props.data.business_address}</td>
                            </tr>
                            <tr>
                                <th scope="row">Contact Person</th>
                                <td>{props.data.contact_person}</td>
                            </tr>
                            <tr>
                                <th scope="row">Contact Number</th>
                                <td>{props.data.contact_number}</td>
                            </tr>
                            <tr>
                                <th scope="row">Contact Email</th>
                                <td>{props.data.contact_email}</td>
                            </tr>
                            <tr>
                                <th scope="row">Waiting For</th>
                                <td>{props.data.waiting_for}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className={"col-6"}>
                        <h5>Account Notes</h5>
                        <DataTable
                            columns={columns}
                            data={props.data.notes ?? []}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClientAccountDetails;
