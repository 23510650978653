import {
    Configuration,
    PartnerAccountServicesApi,
    PartnerAuthenticationServiceApi, PartnerClientAccountServicesApi,
    PartnerLeadServicesApi, PartnerOrderServicesApi
} from "./gen-partner";
import axios, {AxiosInstance} from "axios";

interface PartnerServicesRegistry {
    partnerAuthenticationService: PartnerAuthenticationServiceApi | null;
    partnerAccountService: PartnerAccountServicesApi | null
    partnerLeadsServices: PartnerLeadServicesApi | null,
    partnerClientAccountService: PartnerClientAccountServicesApi | null
    partnerOrderService: PartnerOrderServicesApi | null
}

let partnerServices: PartnerServicesRegistry = {
    partnerAuthenticationService: null,
    partnerAccountService: null,
    partnerLeadsServices: null,
    partnerClientAccountService: null,
    partnerOrderService: null
}

function _getAxiosInstance(): AxiosInstance {
    const authentication_token = localStorage.getItem("security_token");

    return axios.create({
        headers: {
            'content-type': 'application/json',
            "authorization": (authentication_token === null ? "" : `Bearer ${authentication_token}`)
        }
    });
}

function getAxiosInstance(): AxiosInstance {
    // note: we can't cache axios instance here.

    return _getAxiosInstance();
}

export function getPartnerAuthenticationServiceApi(): PartnerAuthenticationServiceApi {
    if (!partnerServices.partnerAuthenticationService) {
        partnerServices.partnerAuthenticationService = new PartnerAuthenticationServiceApi(new Configuration(), window.location.origin, getAxiosInstance());
    }

    return partnerServices.partnerAuthenticationService;
}

export function getPartnerAccountServicesApi(): PartnerAccountServicesApi {
    if (!partnerServices.partnerAccountService) {
        partnerServices.partnerAccountService = new PartnerAccountServicesApi(new Configuration(), window.location.origin, getAxiosInstance());
    }

    return partnerServices.partnerAccountService;
}

export function getPartnerLeadServicesApi(): PartnerLeadServicesApi {
    if (!partnerServices.partnerLeadsServices) {
        partnerServices.partnerLeadsServices = new PartnerLeadServicesApi(new Configuration(), window.location.origin, getAxiosInstance());
    }

    return partnerServices.partnerLeadsServices;
}

export function getPartnerClientLeadServicesApi(): PartnerClientAccountServicesApi {
    if (!partnerServices.partnerClientAccountService) {
        partnerServices.partnerClientAccountService = new PartnerClientAccountServicesApi(new Configuration(), window.location.origin, getAxiosInstance());
    }

    return partnerServices.partnerClientAccountService;
}

export function getPartnerOrderServicesApi(): PartnerOrderServicesApi {
    if (!partnerServices.partnerOrderService) {
        partnerServices.partnerOrderService = new PartnerOrderServicesApi(new Configuration(), window.location.origin, getAxiosInstance());
    }

    return partnerServices.partnerOrderService;
}
