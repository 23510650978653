import React, {ChangeEvent, FormEvent, Fragment, useEffect, useState} from 'react';
import { getPartnerLeadServicesApi} from "./api";
import {PartnerLead, PartnerLeadState} from "./gen-partner";
import DataTable, {TableColumn} from 'react-data-table-component';
import LeadNotes from "./lead_notes";
import Modal from 'react-modal';
import ReactPaginate from 'react-paginate';
import Datetime from "./datetime";


interface Props {

}

interface NewLead {
    businessName: string
    abnAcn: string
    businessAddress: string
    contactPerson: string
    contactNumber: string
    contactEmail: string
    note: string
}

function Leads(props:Props) {
    const [leads, setLeads] = useState<PartnerLead[]>([]);
    const [modalIsOpen, setIsOpen] =useState(false);
    const [page, setPage] =useState<number>(0);
    const [totalPages, setTotalPages] =useState<number>(1);
    const [newLead, setNewLead] = useState<NewLead>({
        businessName: "",
        abnAcn: "",
        businessAddress: "",
        contactPerson: "",
        contactNumber: "",
        contactEmail: "",
        note:""
    })

    const clearNewLead = () => {
        setNewLead({
            businessName: "",
            abnAcn: "",
            businessAddress: "",
            contactPerson: "",
            contactNumber: "",
            contactEmail: "",
            note: ""
        })
    }

    const handleBusinessName = (e:ChangeEvent<HTMLInputElement>) => {
        setNewLead({...newLead, businessName: e.target.value})
    }

    const handleAbn = (e:ChangeEvent<HTMLInputElement>) => {
        setNewLead({...newLead, abnAcn: e.target.value})
    }

    const handleBusinessAddress = (e:ChangeEvent<HTMLInputElement>) => {
        setNewLead({...newLead, businessAddress: e.target.value})
    }

    const handleContactPerson = (e:ChangeEvent<HTMLInputElement>) => {
        setNewLead({...newLead, contactPerson: e.target.value})
    }

    const handleContactNumber = (e:ChangeEvent<HTMLInputElement>) => {
        setNewLead({...newLead, contactNumber: e.target.value})
    }

    const handleContactEmail = (e:ChangeEvent<HTMLInputElement>) => {
        setNewLead({...newLead, contactEmail: e.target.value})
    }

    const handleNote = (e:ChangeEvent<HTMLTextAreaElement>) => {
        setNewLead({...newLead, note: e.target.value})
    }

    const openModal = () => {
        clearNewLead();
        setIsOpen(true);
    }

    const closeModal = () => {
        clearNewLead();
        setIsOpen(false);
    }

    const reloadLeads = ()=>{
        getPartnerLeadServicesApi().partnerLeadServicesListLeads({
            states:[PartnerLeadState.InReview, PartnerLeadState.Rejected, PartnerLeadState.Confirmed],
            page: page + 1,
            per_page: 50,
        }).then((response)=>{
            if (response.data.success){
                setLeads(response.data.leads ?? [])
                setTotalPages(response.data.total_pages ?? 1);
            } else {
                window.alert(response.data.error_message);
            }
        }).catch((e)=>{
            console.log(e)
            window.alert("Server error");
        }).finally(()=>{

        })
    }


    useEffect(()=>{
        reloadLeads();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    const addNewLeads = (e:FormEvent)=>{
        e.stopPropagation();
        e.preventDefault()
        getPartnerLeadServicesApi().partnerLeadServicesRegisterLead({
            business_name:newLead.businessName,
            abn_acn:newLead.abnAcn,
            business_address:newLead.businessAddress,
            contact_person:newLead.contactPerson,
            contact_number:newLead.contactNumber,
            contact_email:newLead.contactEmail,
            note: newLead.note
        }).then((response)=>{
            if (response.data.success){
                reloadLeads();
                closeModal();
            } else {
                window.alert(response.data.error_message)
            }
        }).catch((e)=>{
            console.log(e)
            window.alert("Server error")
        }).finally(()=>{

        })
    }

    const onPageChange = (selectedItem:{selected: number})=>{
        setPage(selectedItem.selected)
    }

    const columns:TableColumn<PartnerLead>[] = [
        {
            name: 'UUID',
            selector: (row:PartnerLead) => row.uuid ?? "",
            cell: row => row.uuid?.slice(0, 4)
        },
        {
            name: 'Created At',
            selector: (row:PartnerLead) => row.created_epoch ?? "",
            wrap: true,
            cell: row => <Datetime epoch={row.created_epoch}/>,
            sortable: true
        },
        {
            name: 'Updated At',
            selector: (row:PartnerLead) => row.updated_epoch ?? "",
            wrap: true,
            cell: row => <Datetime epoch={row.updated_epoch}/>,
            sortable: true
        },
        {
            name: 'State',
            selector: (row:PartnerLead) => row.state ?? "",
            wrap: true,
        },
        {
            name: 'Business Name',
            selector: (row:PartnerLead) => row.business_name ?? "",
            wrap: true,
        },
        {
            name: 'ABN(ACN)',
            selector: (row:PartnerLead) => row.abn_acn ?? "",
            wrap: true,
        },
        {
            name: 'Business Address',
            selector: (row:PartnerLead) => row.business_address ?? "",
            wrap: true,
        },
        {
            name: 'Contact Name',
            selector: (row:PartnerLead) => row.contact_person ?? "",
            wrap: true,
        },
        {
            name: 'Contact Number',
            selector: (row:PartnerLead) => row.contact_number ?? "",
            wrap: true,
        },
        {
            name: 'Contact Email',
            selector: (row:PartnerLead) => row.contact_email ?? "",
            wrap: true,
        },
    ];

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
        overlay:{zIndex:1000}
    };

    return (
        <Fragment>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className={"container"}>
                    <h5>Register a new lead</h5>
                    <form className="row g-3" onSubmit={addNewLeads}>
                        <div className="col-12">
                            <label className="form-label">Business Name</label>
                            <input type="text" className="form-control" placeholder="Vending on Track" onChange={handleBusinessName} value={newLead.businessName}/>
                        </div>
                        <div className="col-12">
                            <label className="form-label">ABN(ACN)</label>
                            <input type="text" className="form-control" placeholder="00000000000" onChange={handleAbn} value={newLead.abnAcn}/>
                        </div>
                        <div className="col-12">
                            <label className="form-label">Business Address</label>
                            <input type="text" className="form-control" placeholder="56 Delhi Rd, Macquarie Park" onChange={handleBusinessAddress} value={newLead.businessAddress}/>
                        </div>
                        <div className="col-12">
                            <label className="form-label">Contact Person</label>
                            <input type="text" className="form-control" placeholder="Name" onChange={handleContactPerson} value={newLead.contactPerson}/>
                        </div>
                        <div className="col-12">
                            <label className="form-label">Contact Number</label>
                            <input type="text" className="form-control" placeholder="0400 000 000" onChange={handleContactNumber} value={newLead.contactNumber}/>
                        </div>
                        <div className="col-12">
                            <label className="form-label">Contact Email</label>
                            <input type="text" className="form-control" placeholder="name@domain.com" onChange={handleContactEmail} value={newLead.contactEmail}/>
                        </div>
                        <div className="col-12">
                            <label className="form-label">Additional Notes</label>
                            <textarea className="form-control" aria-label="With textarea" onChange={handleNote} value={newLead.note}></textarea>
                        </div>
                        <div className="col-12">
                            <button type="submit" className="btn btn-primary">Register</button>
                        </div>
                    </form>
                </div>
            </Modal>
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Leads</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <button className={"btn btn-light"} onClick={openModal}>
                            New Leads
                        </button>
                    </div>
                </div>
                <div>
                    <h5>Leads table</h5>
                    <DataTable
                        columns={columns}
                        data={leads}
                        expandableRows={true}
                        expandableRowsComponent={LeadNotes}
                    />
                    <ReactPaginate
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        nextLabel="next >"
                        onPageChange={onPageChange}
                        pageRangeDisplayed={5}
                        pageCount={totalPages}
                        previousLabel="< previous"
                    />
                </div>
            </main>
        </Fragment>
    );
}

export default Leads;
