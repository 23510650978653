import React, {ChangeEvent, Fragment, useEffect, useState} from 'react';
import {
    PartnerClientAccount,
    PartnerCreditCardReader,
    PartnerCreditCardReaderCombo, PartnerPpeVendingMobile,
    PartnerVendcoinModem
} from "./gen-partner";
import {getPartnerClientLeadServicesApi, getPartnerOrderServicesApi} from "./api";
import {useParams} from "react-router-dom";
import DataTable, {TableColumn} from "react-data-table-component";
import NewCreditCardReaderComboModal from "./new_credit_card_reader_combo_modal";
import NewVendcoinModemModal from "./new_vendcoin_modem_modal";
import NewCreditCardReaderModal from "./new_credit_card_reader_modal";
import NewPpeVendingMobileModal from "./new_ppe_vending_mobile_modal";
import {useNavigate} from "react-router-dom";

interface Props {

}

function OrderPlacement(props:Props) {
    const params = useParams();
    const navigate = useNavigate()
    const [account, setAccount] = useState<PartnerClientAccount | null>(null);
    const [creditCardReaderCombos, setCreditCardReaderCombos] = useState<PartnerCreditCardReaderCombo[]>([]);
    const [isCreditCardReaderComboModalOpen, setIsCreditCardReaderComboModalOpen] = useState<boolean>(false);
    const [vendcoinModems, setVendcoinModems] = useState<PartnerVendcoinModem[]>([]);
    const [isVendcoinModemsModalOpen, setIsVendcoinModemsModalOpen] = useState<boolean>(false);
    const [creditCardReaders, setCreditCardReaders] = useState<PartnerCreditCardReader[]>([]);
    const [isCreditCardReaderModalOpen, setIsCreditCardReaderModalOpen] = useState<boolean>(false);
    const [ppeVendingMobiles, setPpeVendingMobiles] = useState<PartnerPpeVendingMobile[]>([]);
    const [isPpeVendingMobilesModalOpen, setIsPpeVendingMobilesModalOpen] = useState<boolean>(false);
    const [additionalInformation, setAdditionalInformation] = useState<string>("");
    const [note, setNote] = useState<string>("")

    const getAccountDetail = () => {
        getPartnerClientLeadServicesApi().partnerClientAccountServicesGetClientAccount(params.uuid ?? "").then((response)=>{
            if (response.data.success){
                setAccount(response.data.account ?? null)
            } else {
                window.alert(response.data.error_message)
                navigate(`/accounts`, {replace: true})
            }
        }).catch((e)=>{
            console.log(e)
            window.alert("Server error");
        })
    }

    useEffect(()=>{
        getAccountDetail()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const creditCardReaderCombosColumns:TableColumn<PartnerCreditCardReaderCombo>[] = [
        {
            name: 'Index',
            selector: (row:PartnerCreditCardReaderCombo, rowIndex) => (rowIndex ?? 0) + 1,
            wrap: true,
            sortable: true
        },
        {
            name: 'Wim Board',
            selector: (row:PartnerCreditCardReaderCombo) => row.modem_configuration?.wim_board ?? "",
            cell: row => (row.modem_configuration?.wim_board ?? false).toString(),
            wrap: true,
            sortable: true
        },
        {
            name: 'Region',
            selector: (row:PartnerCreditCardReaderCombo) => row.modem_configuration?.region ?? "",
        },
        {
            name: 'Dex Option',
            selector: (row:PartnerCreditCardReaderCombo) => row.dex_option ?? "",
        },
        {
            name: 'Sim Card Option',
            selector: (row:PartnerCreditCardReaderCombo) => row.sim_card_option ?? "",
        },
        {
            name: 'Mounting Plate Option',
            selector: (row:PartnerCreditCardReaderCombo) => row.mounting_plate_option ?? "",
        },
        {
            name: 'Operation',
            selector: (row:PartnerCreditCardReaderCombo, rowIndex) => rowIndex ?? "",
            cell: (row, rowIndex) => {
                return <button className={"btn btn-warning"} onClick={(e:React.MouseEvent<HTMLButtonElement>)=>{e.preventDefault(); removeCreditCardReaderCombo(rowIndex)}}>remove</button>
            }
        },
    ];

    const vendcoinModemsColumns:TableColumn<PartnerVendcoinModem>[] = [
        {
            name: 'Index',
            selector: (row:PartnerVendcoinModem, rowIndex) => (rowIndex ?? 0) + 1,
            wrap: true,
            sortable: true
        },
        {
            name: 'Wim Board',
            selector: (row:PartnerVendcoinModem) => row.modem_configuration?.wim_board ?? "",
            cell: row => (row.modem_configuration?.wim_board ?? false).toString(),
            wrap: true,
            sortable: true
        },
        {
            name: 'Region',
            selector: (row:PartnerVendcoinModem) => row.modem_configuration?.region ?? "",
        },
        {
            name: 'Dex Option',
            selector: (row:PartnerVendcoinModem) => row.dex_option ?? "",
        },
        {
            name: 'Sim Card Option',
            selector: (row:PartnerVendcoinModem) => row.sim_card_option ?? "",
        },
        {
            name: 'Operation',
            selector: (row:PartnerVendcoinModem, rowIndex) => rowIndex ?? "",
            cell: (row, rowIndex) => {
                return <button className={"btn btn-warning"} onClick={(e:React.MouseEvent<HTMLButtonElement>)=>{e.preventDefault(); removeVendcoinModem(rowIndex)}}>remove</button>
            }
        },
    ];

    const creditCardReaderColumns:TableColumn<PartnerCreditCardReader>[] = [
        {
            name: 'Index',
            selector: (row:PartnerCreditCardReader, rowIndex) => (rowIndex ?? 0) + 1,
            wrap: true,
            sortable: true
        },
        {
            name: 'Mounting Plate Option',
            selector: (row:PartnerCreditCardReader) => row.mounting_plate_option ?? "",
        },
        {
            name: 'Operation',
            selector: (row:PartnerCreditCardReader, rowIndex) => rowIndex ?? "",
            cell: (row, rowIndex) => {
                return <button className={"btn btn-warning"} onClick={(e:React.MouseEvent<HTMLButtonElement>)=>{e.preventDefault(); removeCreditCardReader(rowIndex)}}>remove</button>
            }
        },
    ];

    const ppeVendingMobileColumns:TableColumn<PartnerPpeVendingMobile>[] = [
        {
            name: 'Index',
            selector: (row:PartnerPpeVendingMobile, rowIndex) => (rowIndex ?? 0) + 1,
            wrap: true,
            sortable: true
        },
        {
            name: 'Wim Board',
            selector: (row:PartnerPpeVendingMobile) => row.modem_configuration?.wim_board ?? "",
            cell: row => (row.modem_configuration?.wim_board ?? false).toString(),
            wrap: true,
            sortable: true
        },
        {
            name: 'Region',
            selector: (row:PartnerPpeVendingMobile) => row.modem_configuration?.region ?? "",
        },
        {
            name: 'Sim Card Option',
            selector: (row:PartnerPpeVendingMobile) => row.sim_card_option ?? "",
        },
        {
            name: 'Operation',
            selector: (row:PartnerPpeVendingMobile, rowIndex) => rowIndex ?? "",
            cell: (row, rowIndex) => {
                return <button className={"btn btn-warning"} onClick={(e:React.MouseEvent<HTMLButtonElement>)=>{e.preventDefault(); removePpeVendingMobile(rowIndex)}}>remove</button>
            }
        },
    ];

    const closeCreditCardReaderComboModal = ()=>{
        setIsCreditCardReaderComboModalOpen(false)
    }

    const openCreditCardReaderComboModal = ()=>{
        setIsCreditCardReaderComboModalOpen(true)
    }

    const onCreditCardReaderComboCreated = (creditCardReaderCombo: PartnerCreditCardReaderCombo)=>{
        let _creditCardReaderCombos = creditCardReaderCombos;
        _creditCardReaderCombos.push(creditCardReaderCombo)
        setCreditCardReaderCombos(_creditCardReaderCombos)
    }

    const removeCreditCardReaderCombo = (index: number)=> {
        let _creditCardReaderCombos = creditCardReaderCombos.filter((combo, _index)=>{
            return _index !== index;
        });
        setCreditCardReaderCombos(_creditCardReaderCombos)
    }

    const closeVendcoinModemModal = ()=>{
        setIsVendcoinModemsModalOpen(false)
    }

    const openVendcoinModemModal = ()=>{
        setIsVendcoinModemsModalOpen(true)
    }

    const onVendcoinModemCreated = (vendcoinModem: PartnerVendcoinModem)=>{
        let _vendcoinModems = vendcoinModems;
        _vendcoinModems.push(vendcoinModem)
        setVendcoinModems(_vendcoinModems)
    }

    const removeVendcoinModem = (index: number)=> {
        let _vendcoinModems = vendcoinModems.filter((vendcoinModem, _index)=>{
            return _index !== index;
        });
        setVendcoinModems(_vendcoinModems)
    }

    const closeCreditCardReaderModal = ()=>{
        setIsCreditCardReaderModalOpen(false)
    }

    const openCreditCardReaderModal = ()=>{
        setIsCreditCardReaderModalOpen(true)
    }

    const onCreditCardReaderCreated = (creditCardReader: PartnerCreditCardReader)=>{
        let _creditCardReaders = creditCardReaders;
        _creditCardReaders.push(creditCardReader)
        setCreditCardReaders(_creditCardReaders)
    }

    const removeCreditCardReader = (index: number)=> {
        let _creditCardReaders = creditCardReaders.filter((reader, _index)=>{
            return _index !== index;
        });
        setCreditCardReaders(_creditCardReaders)
    }

    const closePpeVendingMobileModal = ()=>{
        setIsPpeVendingMobilesModalOpen(false)
    }

    const openPpeVendingMobileModal = ()=>{
        setIsPpeVendingMobilesModalOpen(true)
    }

    const onPpeVendingMobileCreated = (ppeVendingMobile: PartnerPpeVendingMobile)=>{
        let _ppeVendingMobiles = ppeVendingMobiles;
        _ppeVendingMobiles.push(ppeVendingMobile)
        setPpeVendingMobiles(_ppeVendingMobiles)
    }

    const removePpeVendingMobile = (index: number)=> {
        let _ppeVendingMobiles = ppeVendingMobiles.filter((ppe, _index)=>{
            return _index !== index;
        });
        setPpeVendingMobiles(_ppeVendingMobiles)
    }

    const handleAdditionalRequest = (e:ChangeEvent<HTMLTextAreaElement>) => {
        setAdditionalInformation(e.target.value);
    }

    const handleNote = (e:ChangeEvent<HTMLTextAreaElement>) => {
        setNote(e.target.value);
    }

    const placeOrder = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (window.confirm("Are your sure to place the order?")){
            getPartnerOrderServicesApi().partnerOrderServicesPlaceOrder({
                account_uuid: account?.uuid,
                credit_card_reader_combos: creditCardReaderCombos,
                vendcoin_modems: vendcoinModems,
                credit_card_readers: creditCardReaders,
                ppe_vending_mobiles: ppeVendingMobiles,
                other: additionalInformation,
                note: note
            }).then((response)=>{
                if (response.data.success){
                    window.alert("Order Created")
                    navigate(`/orders`, {replace: true})
                } else {
                    window.alert(response.data.error_message)
                }
            }).catch((e)=>{
                console.log(e)
                window.alert("Server error")
            })
        }
    }

    return (
        <Fragment>
            <NewCreditCardReaderComboModal
                isOpen={isCreditCardReaderComboModalOpen}
                onclose={closeCreditCardReaderComboModal}
                onCreditCardReaderComboCreated={onCreditCardReaderComboCreated}/>
            <NewVendcoinModemModal
                onVendcoinModemCreated={onVendcoinModemCreated}
                onclose={closeVendcoinModemModal}
                isOpen={isVendcoinModemsModalOpen}/>
            <NewCreditCardReaderModal
                onCreditCardReaderCreated={onCreditCardReaderCreated}
                isOpen={isCreditCardReaderModalOpen}
                onclose={closeCreditCardReaderModal}/>
            <NewPpeVendingMobileModal
                onPpeVendingMobileCreated={onPpeVendingMobileCreated}
                isOpen={isPpeVendingMobilesModalOpen}
                onclose={closePpeVendingMobileModal}/>
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 pb-5">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Place order for account {account?.business_name}</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                    </div>
                </div>
                <div>
                    <div className={"alert alert-success"}>
                        <div className={"container-fluid"}>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <h5>Credit Card Reader Combo Order</h5>
                                </div>
                                <div className={"col"}>
                                    <button className={"btn btn-light float-end mb-1"} onClick={openCreditCardReaderComboModal}>Add</button>
                                </div>
                            </div>
                            <DataTable
                                columns={creditCardReaderCombosColumns}
                                data={creditCardReaderCombos}
                            />
                        </div>
                    </div>
                    <div className={"alert alert-success"}>
                        <div className={"container-fluid"}>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <h5>Vendcoin Modems Order</h5>
                                </div>
                                <div className={"col"}>
                                    <button className={"btn btn-light float-end mb-1"} onClick={openVendcoinModemModal}>Add</button>
                                </div>
                            </div>
                            <DataTable
                                columns={vendcoinModemsColumns}
                                data={vendcoinModems}
                            />
                        </div>
                    </div>
                    <div className={"alert alert-success"}>
                        <div className={"container-fluid"}>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <h5>Credit Card Readers Order</h5>
                                </div>
                                <div className={"col"}>
                                    <button className={"btn btn-light float-end mb-1"} onClick={openCreditCardReaderModal}>Add</button>
                                </div>
                            </div>
                            <DataTable
                                columns={creditCardReaderColumns}
                                data={creditCardReaders}
                            />
                        </div>
                    </div>
                    <div className={"alert alert-success"}>
                        <div className={"container-fluid"}>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <h5>Ppe Vending Mobiles Order</h5>
                                </div>
                                <div className={"col"}>
                                    <button className={"btn btn-light float-end mb-1"} onClick={openPpeVendingMobileModal}>Add</button>
                                </div>
                            </div>
                            <DataTable
                                columns={ppeVendingMobileColumns}
                                data={ppeVendingMobiles}
                            />
                        </div>
                    </div>

                    <div className={"alert alert-success"}>
                        <div className={"container-fluid"}>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <h5>Others</h5>
                                </div>
                            </div>
                            <div>
                                <form action="">
                                    <div className="mb-3">
                                        <label className="form-label">Additional Request</label>
                                        <textarea className="form-control" value={additionalInformation} onChange={handleAdditionalRequest}></textarea>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Notes</label>
                                        <textarea className="form-control" value={note} onChange={handleNote}></textarea>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-light float-end" onClick={placeOrder}>Place order</button>
                </div>
            </main>
        </Fragment>
    );
}

export default OrderPlacement;
