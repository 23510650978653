import React from 'react';
import {IAuthenticationContext, withAuthentication} from "./authentication";
import {Navigate} from "react-router-dom";

interface Props extends IAuthenticationContext {
    children:any
}

function _AuthenticationProtected(props: Props) {
    if (props.authentication.authenticated === null) {
            return (<div/>); // TODO: it should be a spinner
    }

    if (!props.authentication.authenticated) {
        return (<Navigate to="/login"/>);
    }

    if (props.authentication.authenticated) {
            return props.children
    }
}

const AuthenticationProtected = withAuthentication(_AuthenticationProtected);
export {AuthenticationProtected};
