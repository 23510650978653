/* tslint:disable */
/* eslint-disable */
/**
 * partner.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {Array<PartnerAccessLevel>}
     * @memberof InlineObject
     */
    'access_levels'?: Array<PartnerAccessLevel>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PartnerAccessLevel = {
    FullAccess: 'FULL_ACCESS',
    LimitedAccess: 'LIMITED_ACCESS'
} as const;

export type PartnerAccessLevel = typeof PartnerAccessLevel[keyof typeof PartnerAccessLevel];


/**
 * 
 * @export
 * @interface PartnerClientAccount
 */
export interface PartnerClientAccount {
    /**
     * 
     * @type {string}
     * @memberof PartnerClientAccount
     */
    'uuid'?: string;
    /**
     * 
     * @type {PartnerClientAccountState}
     * @memberof PartnerClientAccount
     */
    'state'?: PartnerClientAccountState;
    /**
     * 
     * @type {number}
     * @memberof PartnerClientAccount
     */
    'created_epoch'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartnerClientAccount
     */
    'updated_epoch'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerClientAccount
     */
    'business_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerClientAccount
     */
    'abn_acn'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerClientAccount
     */
    'business_address'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerClientAccount
     */
    'contact_person'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerClientAccount
     */
    'contact_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerClientAccount
     */
    'contact_email'?: string;
    /**
     * 
     * @type {PartnerRole}
     * @memberof PartnerClientAccount
     */
    'waiting_for'?: PartnerRole;
    /**
     * 
     * @type {string}
     * @memberof PartnerClientAccount
     */
    'lead_uuid'?: string;
    /**
     * 
     * @type {Array<PartnerNote>}
     * @memberof PartnerClientAccount
     */
    'notes'?: Array<PartnerNote>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PartnerClientAccountState = {
    Pending: 'PENDING',
    InProgress: 'IN_PROGRESS',
    Active: 'ACTIVE'
} as const;

export type PartnerClientAccountState = typeof PartnerClientAccountState[keyof typeof PartnerClientAccountState];


/**
 * 
 * @export
 * @interface PartnerCreditCardReader
 */
export interface PartnerCreditCardReader {
    /**
     * 
     * @type {PartnerMountingPlateOption}
     * @memberof PartnerCreditCardReader
     */
    'mounting_plate_option'?: PartnerMountingPlateOption;
}
/**
 * 
 * @export
 * @interface PartnerCreditCardReaderCombo
 */
export interface PartnerCreditCardReaderCombo {
    /**
     * 
     * @type {PartnerMountingPlateOption}
     * @memberof PartnerCreditCardReaderCombo
     */
    'mounting_plate_option'?: PartnerMountingPlateOption;
    /**
     * 
     * @type {PartnerModemConfiguration}
     * @memberof PartnerCreditCardReaderCombo
     */
    'modem_configuration'?: PartnerModemConfiguration;
    /**
     * 
     * @type {PartnerDexOption}
     * @memberof PartnerCreditCardReaderCombo
     */
    'dex_option'?: PartnerDexOption;
    /**
     * 
     * @type {PartnerSimCardOption}
     * @memberof PartnerCreditCardReaderCombo
     */
    'sim_card_option'?: PartnerSimCardOption;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PartnerDexOption = {
    None: 'NONE',
    AudioJack: 'AUDIO_JACK',
    Db9: 'DB9'
} as const;

export type PartnerDexOption = typeof PartnerDexOption[keyof typeof PartnerDexOption];


/**
 * 
 * @export
 * @interface PartnerGetClientAccountResponse
 */
export interface PartnerGetClientAccountResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PartnerGetClientAccountResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerGetClientAccountResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {PartnerClientAccount}
     * @memberof PartnerGetClientAccountResponse
     */
    'account'?: PartnerClientAccount;
}
/**
 * 
 * @export
 * @interface PartnerGetMeResponse
 */
export interface PartnerGetMeResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PartnerGetMeResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerGetMeResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {PartnerPartnerAccount}
     * @memberof PartnerGetMeResponse
     */
    'me'?: PartnerPartnerAccount;
}
/**
 * 
 * @export
 * @interface PartnerGetOrganizationDetailsResponse
 */
export interface PartnerGetOrganizationDetailsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PartnerGetOrganizationDetailsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerGetOrganizationDetailsResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {PartnerOrganization}
     * @memberof PartnerGetOrganizationDetailsResponse
     */
    'organization'?: PartnerOrganization;
}
/**
 * 
 * @export
 * @interface PartnerGrantAccountAccessToLimitedAccessPartnerAccountRequest
 */
export interface PartnerGrantAccountAccessToLimitedAccessPartnerAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof PartnerGrantAccountAccessToLimitedAccessPartnerAccountRequest
     */
    'account_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerGrantAccountAccessToLimitedAccessPartnerAccountRequest
     */
    'partner_account_uuid'?: string;
}
/**
 * 
 * @export
 * @interface PartnerGrantAccountAccessToLimitedAccessPartnerAccountResponse
 */
export interface PartnerGrantAccountAccessToLimitedAccessPartnerAccountResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PartnerGrantAccountAccessToLimitedAccessPartnerAccountResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerGrantAccountAccessToLimitedAccessPartnerAccountResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PartnerLead
 */
export interface PartnerLead {
    /**
     * 
     * @type {string}
     * @memberof PartnerLead
     */
    'uuid'?: string;
    /**
     * 
     * @type {PartnerLeadState}
     * @memberof PartnerLead
     */
    'state'?: PartnerLeadState;
    /**
     * 
     * @type {number}
     * @memberof PartnerLead
     */
    'created_epoch'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartnerLead
     */
    'updated_epoch'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerLead
     */
    'business_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerLead
     */
    'abn_acn'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerLead
     */
    'business_address'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerLead
     */
    'contact_person'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerLead
     */
    'contact_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerLead
     */
    'contact_email'?: string;
    /**
     * 
     * @type {PartnerPartnerAccount}
     * @memberof PartnerLead
     */
    'created_by'?: PartnerPartnerAccount;
    /**
     * 
     * @type {Array<PartnerNote>}
     * @memberof PartnerLead
     */
    'notes'?: Array<PartnerNote>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PartnerLeadState = {
    InReview: 'IN_REVIEW',
    Confirmed: 'CONFIRMED',
    Rejected: 'REJECTED'
} as const;

export type PartnerLeadState = typeof PartnerLeadState[keyof typeof PartnerLeadState];


/**
 * 
 * @export
 * @interface PartnerListClientAccountsRequest
 */
export interface PartnerListClientAccountsRequest {
    /**
     * 
     * @type {number}
     * @memberof PartnerListClientAccountsRequest
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartnerListClientAccountsRequest
     */
    'per_page'?: number;
    /**
     * 
     * @type {Array<PartnerClientAccountState>}
     * @memberof PartnerListClientAccountsRequest
     */
    'states'?: Array<PartnerClientAccountState>;
}
/**
 * 
 * @export
 * @interface PartnerListClientAccountsResponse
 */
export interface PartnerListClientAccountsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PartnerListClientAccountsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerListClientAccountsResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {Array<PartnerClientAccount>}
     * @memberof PartnerListClientAccountsResponse
     */
    'accounts'?: Array<PartnerClientAccount>;
    /**
     * 
     * @type {number}
     * @memberof PartnerListClientAccountsResponse
     */
    'total_pages'?: number;
}
/**
 * 
 * @export
 * @interface PartnerListLeadsRequest
 */
export interface PartnerListLeadsRequest {
    /**
     * 
     * @type {number}
     * @memberof PartnerListLeadsRequest
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartnerListLeadsRequest
     */
    'per_page'?: number;
    /**
     * 
     * @type {Array<PartnerLeadState>}
     * @memberof PartnerListLeadsRequest
     */
    'states'?: Array<PartnerLeadState>;
}
/**
 * 
 * @export
 * @interface PartnerListLeadsResponse
 */
export interface PartnerListLeadsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PartnerListLeadsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerListLeadsResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {Array<PartnerLead>}
     * @memberof PartnerListLeadsResponse
     */
    'leads'?: Array<PartnerLead>;
    /**
     * 
     * @type {number}
     * @memberof PartnerListLeadsResponse
     */
    'total_pages'?: number;
}
/**
 * 
 * @export
 * @interface PartnerListOrdersRequest
 */
export interface PartnerListOrdersRequest {
    /**
     * 
     * @type {number}
     * @memberof PartnerListOrdersRequest
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartnerListOrdersRequest
     */
    'per_page'?: number;
    /**
     * 
     * @type {Array<PartnerOrderState>}
     * @memberof PartnerListOrdersRequest
     */
    'states'?: Array<PartnerOrderState>;
    /**
     * 
     * @type {string}
     * @memberof PartnerListOrdersRequest
     */
    'account_uuid'?: string;
}
/**
 * 
 * @export
 * @interface PartnerListOrdersResponse
 */
export interface PartnerListOrdersResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PartnerListOrdersResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerListOrdersResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {Array<PartnerOrder>}
     * @memberof PartnerListOrdersResponse
     */
    'orders'?: Array<PartnerOrder>;
    /**
     * 
     * @type {number}
     * @memberof PartnerListOrdersResponse
     */
    'total_pages'?: number;
}
/**
 * 
 * @export
 * @interface PartnerListPartnerAccountsResponse
 */
export interface PartnerListPartnerAccountsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PartnerListPartnerAccountsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerListPartnerAccountsResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {Array<PartnerPartnerAccount>}
     * @memberof PartnerListPartnerAccountsResponse
     */
    'partner_accounts'?: Array<PartnerPartnerAccount>;
}
/**
 * 
 * @export
 * @interface PartnerModemConfiguration
 */
export interface PartnerModemConfiguration {
    /**
     * 
     * @type {boolean}
     * @memberof PartnerModemConfiguration
     */
    'wim_board'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerModemConfiguration
     */
    'region'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PartnerMountingPlateOption = {
    Random: 'RANDOM',
    Black: 'BLACK',
    StainlessSteel: 'STAINLESS_STEEL',
    NotRequired: 'NOT_REQUIRED'
} as const;

export type PartnerMountingPlateOption = typeof PartnerMountingPlateOption[keyof typeof PartnerMountingPlateOption];


/**
 * 
 * @export
 * @interface PartnerNote
 */
export interface PartnerNote {
    /**
     * 
     * @type {number}
     * @memberof PartnerNote
     */
    'created_epoch'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerNote
     */
    'content'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerNote
     */
    'created_by'?: string;
}
/**
 * 
 * @export
 * @interface PartnerOrder
 */
export interface PartnerOrder {
    /**
     * 
     * @type {string}
     * @memberof PartnerOrder
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerOrder
     */
    'account_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerOrder
     */
    'account_business_name'?: string;
    /**
     * 
     * @type {Array<PartnerCreditCardReaderCombo>}
     * @memberof PartnerOrder
     */
    'credit_card_reader_combos'?: Array<PartnerCreditCardReaderCombo>;
    /**
     * 
     * @type {Array<PartnerVendcoinModem>}
     * @memberof PartnerOrder
     */
    'vendcoin_modems'?: Array<PartnerVendcoinModem>;
    /**
     * 
     * @type {Array<PartnerCreditCardReader>}
     * @memberof PartnerOrder
     */
    'credit_card_readers'?: Array<PartnerCreditCardReader>;
    /**
     * 
     * @type {Array<PartnerPpeVendingMobile>}
     * @memberof PartnerOrder
     */
    'ppe_vending_mobiles'?: Array<PartnerPpeVendingMobile>;
    /**
     * 
     * @type {string}
     * @memberof PartnerOrder
     */
    'other'?: string;
    /**
     * 
     * @type {Array<PartnerNote>}
     * @memberof PartnerOrder
     */
    'notes'?: Array<PartnerNote>;
    /**
     * 
     * @type {PartnerOrderState}
     * @memberof PartnerOrder
     */
    'state'?: PartnerOrderState;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PartnerOrderState = {
    PendingReview: 'PENDING_REVIEW',
    Preparing: 'PREPARING',
    ReadyForPickup: 'READY_FOR_PICKUP',
    Shipped: 'SHIPPED',
    Fulfilled: 'FULFILLED',
    PendingPayment: 'PENDING_PAYMENT'
} as const;

export type PartnerOrderState = typeof PartnerOrderState[keyof typeof PartnerOrderState];


/**
 * 
 * @export
 * @interface PartnerOrganization
 */
export interface PartnerOrganization {
    /**
     * 
     * @type {string}
     * @memberof PartnerOrganization
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerOrganization
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface PartnerPartnerAccount
 */
export interface PartnerPartnerAccount {
    /**
     * 
     * @type {string}
     * @memberof PartnerPartnerAccount
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPartnerAccount
     */
    'email'?: string;
    /**
     * 
     * @type {PartnerAccessLevel}
     * @memberof PartnerPartnerAccount
     */
    'access_level'?: PartnerAccessLevel;
}
/**
 * 
 * @export
 * @interface PartnerPlaceOrderRequest
 */
export interface PartnerPlaceOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof PartnerPlaceOrderRequest
     */
    'account_uuid'?: string;
    /**
     * 
     * @type {Array<PartnerCreditCardReaderCombo>}
     * @memberof PartnerPlaceOrderRequest
     */
    'credit_card_reader_combos'?: Array<PartnerCreditCardReaderCombo>;
    /**
     * 
     * @type {Array<PartnerVendcoinModem>}
     * @memberof PartnerPlaceOrderRequest
     */
    'vendcoin_modems'?: Array<PartnerVendcoinModem>;
    /**
     * 
     * @type {Array<PartnerCreditCardReader>}
     * @memberof PartnerPlaceOrderRequest
     */
    'credit_card_readers'?: Array<PartnerCreditCardReader>;
    /**
     * 
     * @type {Array<PartnerPpeVendingMobile>}
     * @memberof PartnerPlaceOrderRequest
     */
    'ppe_vending_mobiles'?: Array<PartnerPpeVendingMobile>;
    /**
     * 
     * @type {string}
     * @memberof PartnerPlaceOrderRequest
     */
    'other'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPlaceOrderRequest
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface PartnerPlaceOrderResponse
 */
export interface PartnerPlaceOrderResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PartnerPlaceOrderResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerPlaceOrderResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPlaceOrderResponse
     */
    'order_uuid'?: string;
}
/**
 * 
 * @export
 * @interface PartnerPpeVendingMobile
 */
export interface PartnerPpeVendingMobile {
    /**
     * 
     * @type {PartnerModemConfiguration}
     * @memberof PartnerPpeVendingMobile
     */
    'modem_configuration'?: PartnerModemConfiguration;
    /**
     * 
     * @type {PartnerSimCardOption}
     * @memberof PartnerPpeVendingMobile
     */
    'sim_card_option'?: PartnerSimCardOption;
}
/**
 * 
 * @export
 * @interface PartnerRegisterLeadRequest
 */
export interface PartnerRegisterLeadRequest {
    /**
     * 
     * @type {string}
     * @memberof PartnerRegisterLeadRequest
     */
    'business_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerRegisterLeadRequest
     */
    'abn_acn'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerRegisterLeadRequest
     */
    'business_address'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerRegisterLeadRequest
     */
    'contact_person'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerRegisterLeadRequest
     */
    'contact_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerRegisterLeadRequest
     */
    'contact_email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerRegisterLeadRequest
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface PartnerRegisterLeadResponse
 */
export interface PartnerRegisterLeadResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PartnerRegisterLeadResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerRegisterLeadResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PartnerRequestAuthCodeRequest
 */
export interface PartnerRequestAuthCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof PartnerRequestAuthCodeRequest
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface PartnerRequestAuthCodeResponse
 */
export interface PartnerRequestAuthCodeResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PartnerRequestAuthCodeResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerRequestAuthCodeResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PartnerRole = {
    Customer: 'CUSTOMER',
    Gateway: 'GATEWAY'
} as const;

export type PartnerRole = typeof PartnerRole[keyof typeof PartnerRole];


/**
 * 
 * @export
 * @enum {string}
 */

export const PartnerSimCardOption = {
    Telstra: 'TELSTRA',
    AusThreeMainCarriers: 'AUS_THREE_MAIN_CARRIERS',
    International: 'INTERNATIONAL'
} as const;

export type PartnerSimCardOption = typeof PartnerSimCardOption[keyof typeof PartnerSimCardOption];


/**
 * 
 * @export
 * @interface PartnerVendcoinModem
 */
export interface PartnerVendcoinModem {
    /**
     * 
     * @type {PartnerModemConfiguration}
     * @memberof PartnerVendcoinModem
     */
    'modem_configuration'?: PartnerModemConfiguration;
    /**
     * 
     * @type {PartnerDexOption}
     * @memberof PartnerVendcoinModem
     */
    'dex_option'?: PartnerDexOption;
    /**
     * 
     * @type {PartnerSimCardOption}
     * @memberof PartnerVendcoinModem
     */
    'sim_card_option'?: PartnerSimCardOption;
}
/**
 * 
 * @export
 * @interface PartnerVerifyAuthCodeRequest
 */
export interface PartnerVerifyAuthCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof PartnerVerifyAuthCodeRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerVerifyAuthCodeRequest
     */
    'auth_code'?: string;
}
/**
 * 
 * @export
 * @interface PartnerVerifyAuthCodeResponse
 */
export interface PartnerVerifyAuthCodeResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PartnerVerifyAuthCodeResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerVerifyAuthCodeResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerVerifyAuthCodeResponse
     */
    'security_token'?: string;
}
/**
 * 
 * @export
 * @interface ProtobufAny
 */
export interface ProtobufAny {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ProtobufAny
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface RpcStatus
 */
export interface RpcStatus {
    /**
     * 
     * @type {number}
     * @memberof RpcStatus
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof RpcStatus
     */
    'message'?: string;
    /**
     * 
     * @type {Array<ProtobufAny>}
     * @memberof RpcStatus
     */
    'details'?: Array<ProtobufAny>;
}

/**
 * PartnerAccountServicesApi - axios parameter creator
 * @export
 */
export const PartnerAccountServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get my account info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerAccountServicesGetMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerAccountServicesApi - functional programming interface
 * @export
 */
export const PartnerAccountServicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerAccountServicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get my account info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerAccountServicesGetMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerGetMeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerAccountServicesGetMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerAccountServicesApi - factory interface
 * @export
 */
export const PartnerAccountServicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerAccountServicesApiFp(configuration)
    return {
        /**
         * 
         * @summary get my account info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerAccountServicesGetMe(options?: any): AxiosPromise<PartnerGetMeResponse> {
            return localVarFp.partnerAccountServicesGetMe(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnerAccountServicesApi - object-oriented interface
 * @export
 * @class PartnerAccountServicesApi
 * @extends {BaseAPI}
 */
export class PartnerAccountServicesApi extends BaseAPI {
    /**
     * 
     * @summary get my account info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAccountServicesApi
     */
    public partnerAccountServicesGetMe(options?: AxiosRequestConfig) {
        return PartnerAccountServicesApiFp(this.configuration).partnerAccountServicesGetMe(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnerAuthenticationServiceApi - axios parameter creator
 * @export
 */
export const PartnerAuthenticationServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary request auth code
         * @param {PartnerRequestAuthCodeRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerAuthenticationServiceRequestAuthCode: async (body: PartnerRequestAuthCodeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('partnerAuthenticationServiceRequestAuthCode', 'body', body)
            const localVarPath = `/api/v1/users/authentications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary verify auth code
         * @param {PartnerVerifyAuthCodeRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerAuthenticationServiceVerifyAuthCode: async (body: PartnerVerifyAuthCodeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('partnerAuthenticationServiceVerifyAuthCode', 'body', body)
            const localVarPath = `/api/v1/users/verifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerAuthenticationServiceApi - functional programming interface
 * @export
 */
export const PartnerAuthenticationServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerAuthenticationServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary request auth code
         * @param {PartnerRequestAuthCodeRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerAuthenticationServiceRequestAuthCode(body: PartnerRequestAuthCodeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerRequestAuthCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerAuthenticationServiceRequestAuthCode(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary verify auth code
         * @param {PartnerVerifyAuthCodeRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerAuthenticationServiceVerifyAuthCode(body: PartnerVerifyAuthCodeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerVerifyAuthCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerAuthenticationServiceVerifyAuthCode(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerAuthenticationServiceApi - factory interface
 * @export
 */
export const PartnerAuthenticationServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerAuthenticationServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary request auth code
         * @param {PartnerRequestAuthCodeRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerAuthenticationServiceRequestAuthCode(body: PartnerRequestAuthCodeRequest, options?: any): AxiosPromise<PartnerRequestAuthCodeResponse> {
            return localVarFp.partnerAuthenticationServiceRequestAuthCode(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary verify auth code
         * @param {PartnerVerifyAuthCodeRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerAuthenticationServiceVerifyAuthCode(body: PartnerVerifyAuthCodeRequest, options?: any): AxiosPromise<PartnerVerifyAuthCodeResponse> {
            return localVarFp.partnerAuthenticationServiceVerifyAuthCode(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnerAuthenticationServiceApi - object-oriented interface
 * @export
 * @class PartnerAuthenticationServiceApi
 * @extends {BaseAPI}
 */
export class PartnerAuthenticationServiceApi extends BaseAPI {
    /**
     * 
     * @summary request auth code
     * @param {PartnerRequestAuthCodeRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAuthenticationServiceApi
     */
    public partnerAuthenticationServiceRequestAuthCode(body: PartnerRequestAuthCodeRequest, options?: AxiosRequestConfig) {
        return PartnerAuthenticationServiceApiFp(this.configuration).partnerAuthenticationServiceRequestAuthCode(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary verify auth code
     * @param {PartnerVerifyAuthCodeRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAuthenticationServiceApi
     */
    public partnerAuthenticationServiceVerifyAuthCode(body: PartnerVerifyAuthCodeRequest, options?: AxiosRequestConfig) {
        return PartnerAuthenticationServiceApiFp(this.configuration).partnerAuthenticationServiceVerifyAuthCode(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnerClientAccountServicesApi - axios parameter creator
 * @export
 */
export const PartnerClientAccountServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get single clinet account by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerClientAccountServicesGetClientAccount: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('partnerClientAccountServicesGetClientAccount', 'uuid', uuid)
            const localVarPath = `/api/v1/client-accounts/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list all client account
         * @param {PartnerListClientAccountsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerClientAccountServicesListClientAccounts: async (body: PartnerListClientAccountsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('partnerClientAccountServicesListClientAccounts', 'body', body)
            const localVarPath = `/api/v1/client-account-listing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerClientAccountServicesApi - functional programming interface
 * @export
 */
export const PartnerClientAccountServicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerClientAccountServicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get single clinet account by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerClientAccountServicesGetClientAccount(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerGetClientAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerClientAccountServicesGetClientAccount(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list all client account
         * @param {PartnerListClientAccountsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerClientAccountServicesListClientAccounts(body: PartnerListClientAccountsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerListClientAccountsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerClientAccountServicesListClientAccounts(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerClientAccountServicesApi - factory interface
 * @export
 */
export const PartnerClientAccountServicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerClientAccountServicesApiFp(configuration)
    return {
        /**
         * 
         * @summary get single clinet account by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerClientAccountServicesGetClientAccount(uuid: string, options?: any): AxiosPromise<PartnerGetClientAccountResponse> {
            return localVarFp.partnerClientAccountServicesGetClientAccount(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list all client account
         * @param {PartnerListClientAccountsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerClientAccountServicesListClientAccounts(body: PartnerListClientAccountsRequest, options?: any): AxiosPromise<PartnerListClientAccountsResponse> {
            return localVarFp.partnerClientAccountServicesListClientAccounts(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnerClientAccountServicesApi - object-oriented interface
 * @export
 * @class PartnerClientAccountServicesApi
 * @extends {BaseAPI}
 */
export class PartnerClientAccountServicesApi extends BaseAPI {
    /**
     * 
     * @summary get single clinet account by uuid
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerClientAccountServicesApi
     */
    public partnerClientAccountServicesGetClientAccount(uuid: string, options?: AxiosRequestConfig) {
        return PartnerClientAccountServicesApiFp(this.configuration).partnerClientAccountServicesGetClientAccount(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list all client account
     * @param {PartnerListClientAccountsRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerClientAccountServicesApi
     */
    public partnerClientAccountServicesListClientAccounts(body: PartnerListClientAccountsRequest, options?: AxiosRequestConfig) {
        return PartnerClientAccountServicesApiFp(this.configuration).partnerClientAccountServicesListClientAccounts(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnerLeadServicesApi - axios parameter creator
 * @export
 */
export const PartnerLeadServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary list all leads
         * @param {PartnerListLeadsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerLeadServicesListLeads: async (body: PartnerListLeadsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('partnerLeadServicesListLeads', 'body', body)
            const localVarPath = `/api/v1/leads-listing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary register a lead
         * @param {PartnerRegisterLeadRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerLeadServicesRegisterLead: async (body: PartnerRegisterLeadRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('partnerLeadServicesRegisterLead', 'body', body)
            const localVarPath = `/api/v1/leads-registration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerLeadServicesApi - functional programming interface
 * @export
 */
export const PartnerLeadServicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerLeadServicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary list all leads
         * @param {PartnerListLeadsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerLeadServicesListLeads(body: PartnerListLeadsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerListLeadsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerLeadServicesListLeads(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary register a lead
         * @param {PartnerRegisterLeadRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerLeadServicesRegisterLead(body: PartnerRegisterLeadRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerRegisterLeadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerLeadServicesRegisterLead(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerLeadServicesApi - factory interface
 * @export
 */
export const PartnerLeadServicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerLeadServicesApiFp(configuration)
    return {
        /**
         * 
         * @summary list all leads
         * @param {PartnerListLeadsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerLeadServicesListLeads(body: PartnerListLeadsRequest, options?: any): AxiosPromise<PartnerListLeadsResponse> {
            return localVarFp.partnerLeadServicesListLeads(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary register a lead
         * @param {PartnerRegisterLeadRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerLeadServicesRegisterLead(body: PartnerRegisterLeadRequest, options?: any): AxiosPromise<PartnerRegisterLeadResponse> {
            return localVarFp.partnerLeadServicesRegisterLead(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnerLeadServicesApi - object-oriented interface
 * @export
 * @class PartnerLeadServicesApi
 * @extends {BaseAPI}
 */
export class PartnerLeadServicesApi extends BaseAPI {
    /**
     * 
     * @summary list all leads
     * @param {PartnerListLeadsRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerLeadServicesApi
     */
    public partnerLeadServicesListLeads(body: PartnerListLeadsRequest, options?: AxiosRequestConfig) {
        return PartnerLeadServicesApiFp(this.configuration).partnerLeadServicesListLeads(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary register a lead
     * @param {PartnerRegisterLeadRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerLeadServicesApi
     */
    public partnerLeadServicesRegisterLead(body: PartnerRegisterLeadRequest, options?: AxiosRequestConfig) {
        return PartnerLeadServicesApiFp(this.configuration).partnerLeadServicesRegisterLead(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnerOrderServicesApi - axios parameter creator
 * @export
 */
export const PartnerOrderServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary list orders
         * @param {PartnerListOrdersRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerOrderServicesListOrders: async (body: PartnerListOrdersRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('partnerOrderServicesListOrders', 'body', body)
            const localVarPath = `/api/v1/order-listing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary place an order
         * @param {PartnerPlaceOrderRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerOrderServicesPlaceOrder: async (body: PartnerPlaceOrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('partnerOrderServicesPlaceOrder', 'body', body)
            const localVarPath = `/api/v1/order-placement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerOrderServicesApi - functional programming interface
 * @export
 */
export const PartnerOrderServicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerOrderServicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary list orders
         * @param {PartnerListOrdersRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerOrderServicesListOrders(body: PartnerListOrdersRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerListOrdersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerOrderServicesListOrders(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary place an order
         * @param {PartnerPlaceOrderRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerOrderServicesPlaceOrder(body: PartnerPlaceOrderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerPlaceOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerOrderServicesPlaceOrder(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerOrderServicesApi - factory interface
 * @export
 */
export const PartnerOrderServicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerOrderServicesApiFp(configuration)
    return {
        /**
         * 
         * @summary list orders
         * @param {PartnerListOrdersRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerOrderServicesListOrders(body: PartnerListOrdersRequest, options?: any): AxiosPromise<PartnerListOrdersResponse> {
            return localVarFp.partnerOrderServicesListOrders(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary place an order
         * @param {PartnerPlaceOrderRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerOrderServicesPlaceOrder(body: PartnerPlaceOrderRequest, options?: any): AxiosPromise<PartnerPlaceOrderResponse> {
            return localVarFp.partnerOrderServicesPlaceOrder(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnerOrderServicesApi - object-oriented interface
 * @export
 * @class PartnerOrderServicesApi
 * @extends {BaseAPI}
 */
export class PartnerOrderServicesApi extends BaseAPI {
    /**
     * 
     * @summary list orders
     * @param {PartnerListOrdersRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerOrderServicesApi
     */
    public partnerOrderServicesListOrders(body: PartnerListOrdersRequest, options?: AxiosRequestConfig) {
        return PartnerOrderServicesApiFp(this.configuration).partnerOrderServicesListOrders(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary place an order
     * @param {PartnerPlaceOrderRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerOrderServicesApi
     */
    public partnerOrderServicesPlaceOrder(body: PartnerPlaceOrderRequest, options?: AxiosRequestConfig) {
        return PartnerOrderServicesApiFp(this.configuration).partnerOrderServicesPlaceOrder(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnerOrganizationServicesApi - axios parameter creator
 * @export
 */
export const PartnerOrganizationServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get organization details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerOrganizationServicesGetOrganizationDetails: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/organization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary grant client account access to limited access partner account by full partner account
         * @param {PartnerGrantAccountAccessToLimitedAccessPartnerAccountRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerOrganizationServicesGrantAccountAccessToLimitedAccessPartnerAccount: async (body: PartnerGrantAccountAccessToLimitedAccessPartnerAccountRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('partnerOrganizationServicesGrantAccountAccessToLimitedAccessPartnerAccount', 'body', body)
            const localVarPath = `/api/v1/organization/account-access-grant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list partner accounts that belong to the organization
         * @param {string} organizationUuid 
         * @param {InlineObject} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerOrganizationServicesListPartnerAccounts: async (organizationUuid: string, body: InlineObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationUuid' is not null or undefined
            assertParamExists('partnerOrganizationServicesListPartnerAccounts', 'organizationUuid', organizationUuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('partnerOrganizationServicesListPartnerAccounts', 'body', body)
            const localVarPath = `/api/v1/organization/{organization_uuid}/partner-accounts-listing`
                .replace(`{${"organization_uuid"}}`, encodeURIComponent(String(organizationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerOrganizationServicesApi - functional programming interface
 * @export
 */
export const PartnerOrganizationServicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerOrganizationServicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get organization details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerOrganizationServicesGetOrganizationDetails(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerGetOrganizationDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerOrganizationServicesGetOrganizationDetails(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary grant client account access to limited access partner account by full partner account
         * @param {PartnerGrantAccountAccessToLimitedAccessPartnerAccountRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerOrganizationServicesGrantAccountAccessToLimitedAccessPartnerAccount(body: PartnerGrantAccountAccessToLimitedAccessPartnerAccountRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerGrantAccountAccessToLimitedAccessPartnerAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerOrganizationServicesGrantAccountAccessToLimitedAccessPartnerAccount(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list partner accounts that belong to the organization
         * @param {string} organizationUuid 
         * @param {InlineObject} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerOrganizationServicesListPartnerAccounts(organizationUuid: string, body: InlineObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerListPartnerAccountsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerOrganizationServicesListPartnerAccounts(organizationUuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerOrganizationServicesApi - factory interface
 * @export
 */
export const PartnerOrganizationServicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerOrganizationServicesApiFp(configuration)
    return {
        /**
         * 
         * @summary get organization details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerOrganizationServicesGetOrganizationDetails(options?: any): AxiosPromise<PartnerGetOrganizationDetailsResponse> {
            return localVarFp.partnerOrganizationServicesGetOrganizationDetails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary grant client account access to limited access partner account by full partner account
         * @param {PartnerGrantAccountAccessToLimitedAccessPartnerAccountRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerOrganizationServicesGrantAccountAccessToLimitedAccessPartnerAccount(body: PartnerGrantAccountAccessToLimitedAccessPartnerAccountRequest, options?: any): AxiosPromise<PartnerGrantAccountAccessToLimitedAccessPartnerAccountResponse> {
            return localVarFp.partnerOrganizationServicesGrantAccountAccessToLimitedAccessPartnerAccount(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list partner accounts that belong to the organization
         * @param {string} organizationUuid 
         * @param {InlineObject} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerOrganizationServicesListPartnerAccounts(organizationUuid: string, body: InlineObject, options?: any): AxiosPromise<PartnerListPartnerAccountsResponse> {
            return localVarFp.partnerOrganizationServicesListPartnerAccounts(organizationUuid, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnerOrganizationServicesApi - object-oriented interface
 * @export
 * @class PartnerOrganizationServicesApi
 * @extends {BaseAPI}
 */
export class PartnerOrganizationServicesApi extends BaseAPI {
    /**
     * 
     * @summary get organization details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerOrganizationServicesApi
     */
    public partnerOrganizationServicesGetOrganizationDetails(options?: AxiosRequestConfig) {
        return PartnerOrganizationServicesApiFp(this.configuration).partnerOrganizationServicesGetOrganizationDetails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary grant client account access to limited access partner account by full partner account
     * @param {PartnerGrantAccountAccessToLimitedAccessPartnerAccountRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerOrganizationServicesApi
     */
    public partnerOrganizationServicesGrantAccountAccessToLimitedAccessPartnerAccount(body: PartnerGrantAccountAccessToLimitedAccessPartnerAccountRequest, options?: AxiosRequestConfig) {
        return PartnerOrganizationServicesApiFp(this.configuration).partnerOrganizationServicesGrantAccountAccessToLimitedAccessPartnerAccount(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list partner accounts that belong to the organization
     * @param {string} organizationUuid 
     * @param {InlineObject} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerOrganizationServicesApi
     */
    public partnerOrganizationServicesListPartnerAccounts(organizationUuid: string, body: InlineObject, options?: AxiosRequestConfig) {
        return PartnerOrganizationServicesApiFp(this.configuration).partnerOrganizationServicesListPartnerAccounts(organizationUuid, body, options).then((request) => request(this.axios, this.basePath));
    }
}


