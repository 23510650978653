import React, {Fragment} from 'react';
import {Link} from "react-router-dom";

interface Props {

}
function Navbar(props:React.PropsWithChildren<Props>) {
    return (
        <Fragment>
            <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
                <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6" href={"/"}>Vending on Track Partner Portal</a>
                <button className="navbar-toggler position-absolute d-md-none collapsed" type="button"
                        data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
            </header>
            <div className="container-fluid" style={{height:"100%"}}>
                <div className="row" style={{height:"100%"}}>
                    <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
                        <div className="position-sticky pt-3">
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <div className={"nav-link"}>
                                        <i className="fa-solid fa-house"></i> &nbsp;
                                        <Link className={"sidebar-link"} to={"/"}>Dashboard</Link>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div className={"nav-link"}>
                                        <i className="fa-solid fa-headset"></i> &nbsp;
                                        <Link className={"sidebar-link"} to={"/leads"}>Leads</Link>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div className={"nav-link"}>
                                        <i className="fa-solid fa-file-invoice"></i> &nbsp;
                                        <Link className={"sidebar-link"} to={"/accounts"}>Account</Link>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div className={"nav-link"}>
                                        <i className="fa-solid fa-basket-shopping"></i> &nbsp;
                                        <Link className={"sidebar-link"} to={"/orders"}>Orders</Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </nav>
                    {props.children}
                </div>
            </div>
        </Fragment>
    );
}

export default Navbar;
