import React from 'react';

export interface Authentication {
    authenticated: boolean | null
    email: string | null
    security_token: string | null
}



export interface IAuthenticationContext {
    authentication: Authentication;

    login(security_token: string, email: string): void;

    logout(): void;
}

const AuthenticationContext = React.createContext<IAuthenticationContext>(
    {
        authentication: {
            authenticated: null,
            email: null,
            security_token: null
        },
        logout: () => {
        },
        login: (t, e): Promise<any> => {
            return new Promise<any>(() => {
            })
        },
    }
);

function withAuthentication(C: any) {
    return function AuthenticationComponent(props: any) {
        return (
            <AuthenticationContext.Consumer>
                {({authentication, login, logout}) =>
                    <C {...props} authentication={authentication} login={login} logout={logout}/>}
            </AuthenticationContext.Consumer>
        );
    }
}

export {AuthenticationContext, withAuthentication}
