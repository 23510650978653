import React, {useEffect, useState} from 'react';
import { Routes, Route } from "react-router-dom";
import './App.css';
import Login from "./login";
import {Authentication, AuthenticationContext} from "./authentication"
import {getPartnerAccountServicesApi} from "./api";
import Dashboard from "./dashboard";
import {AuthenticationProtected} from "./authentication_protected";
import 'bootstrap/dist/js/bootstrap.js';
import Accounts from "./accounts";
import Navbar from "./navbar";
import Leads from "./leads";
import Orders from "./orders";
import OrderPlacement from "./order_placement";

function App() {
    const [authentication, setAuthentication] = useState<Authentication>({
        authenticated: null,
        email: null,
        security_token: null
    });

    const login = (security_token: string, email: string) => {
        localStorage.setItem("security_token", security_token);
        localStorage.setItem("email", email);
        if (security_token != null){
            getPartnerAccountServicesApi().partnerAccountServicesGetMe().then((response)=>{
                if (response.data.success){
                    setAuthentication({
                        authenticated: true,
                        email: response.data.me?.email ?? "",
                        security_token: security_token
                    })
                    console.log("partner authenticated")
                } else {
                    setAuthentication({
                        authenticated: false,
                        email: null,
                        security_token: null
                    })
                    localStorage.removeItem("security_token");
                    localStorage.removeItem("email");
                }
            }).catch(()=>{
                window.alert("Server error")
            })
        } else {
            setAuthentication({
                authenticated: false,
                email: null,
                security_token: null
            })
            localStorage.removeItem("security_token");
            localStorage.removeItem("email");
        }
    }

    const init = () => {
        const security_token = localStorage.getItem("security_token");
        const email = localStorage.getItem("email");
        if (security_token != null && email != null){
            login(security_token, email)
        } else {
            setAuthentication({
                authenticated:false,
                email:null,
                security_token:null
            })
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(init, [])

  return (
    <div className="App">
        <AuthenticationContext.Provider value={{
            authentication:authentication,
            logout:()=>{},
            login:login
        }}>
            <Routes>
                <Route path="/login" element={<Login/>} />
                <Route path="/" element={<AuthenticationProtected><Navbar><Dashboard/></Navbar></AuthenticationProtected>} />
                <Route path="/leads" element={<AuthenticationProtected><Navbar><Leads/></Navbar></AuthenticationProtected>} />
                <Route path="/accounts" element={<AuthenticationProtected><Navbar><Accounts/></Navbar></AuthenticationProtected>} />
                <Route path="/accounts/:uuid/order-placements" element={<AuthenticationProtected><Navbar><OrderPlacement/></Navbar></AuthenticationProtected>} />
                <Route path="/orders" element={<AuthenticationProtected><Navbar><Orders/></Navbar></AuthenticationProtected>} />
            </Routes>
        </AuthenticationContext.Provider>
    </div>
  );
}

export default App;
