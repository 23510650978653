import React, {ChangeEvent, useState} from 'react';
import Modal from 'react-modal';
import {
    PartnerCreditCardReader,
    PartnerMountingPlateOption,
} from "./gen-partner";

interface Props {
    onCreditCardReaderCreated(creditCardReader: PartnerCreditCardReader): void
    isOpen: boolean
    onclose():void
}

function NewCreditCardReaderModal(props: Props) {
    const [creditCardReader, setCreditCardReader] = useState<PartnerCreditCardReader>({
        mounting_plate_option: PartnerMountingPlateOption.NotRequired,
    });
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
        overlay:{zIndex:1000}
    };

    const closeModal = ()=>{
        props.onclose();
    }

    const newCreditCardReader = (e:React.MouseEvent<HTMLButtonElement>)=>{
        e.preventDefault();
        e.stopPropagation();
        props.onCreditCardReaderCreated(creditCardReader)
        closeModal();
    }

    const handleMountingPlateOption = (e: ChangeEvent<HTMLSelectElement>)=>{
        setCreditCardReader({...creditCardReader, mounting_plate_option: e.target.value as PartnerMountingPlateOption})
    }


    return (
        <Modal
            isOpen={props.isOpen}
            onRequestClose={closeModal}
            style={customStyles}
            ariaHideApp={false}
        >
            <div className={"container"}>
                <h5>New Credit Card Reader Combo Configurations</h5>
                <form className="row g-3">
                    <div className="col-12">
                        <label className="form-label">Mounting Plate Options</label>
                        <select className="form-select" value={creditCardReader.mounting_plate_option} onChange={handleMountingPlateOption}>
                            <option value={PartnerMountingPlateOption.NotRequired}>Not Required</option>
                            <option value={PartnerMountingPlateOption.Black}>Black</option>
                            <option value={PartnerMountingPlateOption.StainlessSteel}>Stainless Steel</option>
                            <option value={PartnerMountingPlateOption.Random}>Random</option>
                        </select>
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary" onClick={newCreditCardReader}>Add</button>
                    </div>
                </form>
            </div>

        </Modal>
    );
}

export default NewCreditCardReaderModal;
