import React from 'react';
import DataTable, {ExpanderComponentProps, TableColumn} from "react-data-table-component";
import {
    PartnerCreditCardReader,
    PartnerCreditCardReaderCombo, PartnerNote,
    PartnerOrder,
    PartnerPpeVendingMobile,
    PartnerVendcoinModem
} from "./gen-partner";
import Datetime from "./datetime";

interface Props extends ExpanderComponentProps<PartnerOrder> {

}

function OrderDetails(props:Props) {
    const creditCardReaderCombosColumns:TableColumn<PartnerCreditCardReaderCombo>[] = [
        {
            name: 'Index',
            selector: (row:PartnerCreditCardReaderCombo, rowIndex) => (rowIndex ?? 0) + 1,
            wrap: true,
            sortable: true
        },
        {
            name: 'Wim Board',
            selector: (row:PartnerCreditCardReaderCombo) => row.modem_configuration?.wim_board ?? "",
            cell: row => (row.modem_configuration?.wim_board ?? false).toString(),
            wrap: true,
            sortable: true
        },
        {
            name: 'Region',
            selector: (row:PartnerCreditCardReaderCombo) => row.modem_configuration?.region ?? "",
        },
        {
            name: 'Dex Option',
            selector: (row:PartnerCreditCardReaderCombo) => row.dex_option ?? "",
        },
        {
            name: 'Sim Card Option',
            selector: (row:PartnerCreditCardReaderCombo) => row.sim_card_option ?? "",
        },
        {
            name: 'Mounting Plate Option',
            selector: (row:PartnerCreditCardReaderCombo) => row.mounting_plate_option ?? "",
        },
    ];

    const vendcoinModemsColumns:TableColumn<PartnerVendcoinModem>[] = [
        {
            name: 'Index',
            selector: (row:PartnerVendcoinModem, rowIndex) => (rowIndex ?? 0) + 1,
            wrap: true,
            sortable: true
        },
        {
            name: 'Wim Board',
            selector: (row:PartnerVendcoinModem) => row.modem_configuration?.wim_board ?? "",
            cell: row => (row.modem_configuration?.wim_board ?? false).toString(),
            wrap: true,
            sortable: true
        },
        {
            name: 'Region',
            selector: (row:PartnerVendcoinModem) => row.modem_configuration?.region ?? "",
        },
        {
            name: 'Dex Option',
            selector: (row:PartnerVendcoinModem) => row.dex_option ?? "",
        },
        {
            name: 'Sim Card Option',
            selector: (row:PartnerVendcoinModem) => row.sim_card_option ?? "",
        },
    ];

    const creditCardReaderColumns:TableColumn<PartnerCreditCardReader>[] = [
        {
            name: 'Index',
            selector: (row:PartnerCreditCardReader, rowIndex) => (rowIndex ?? 0) + 1,
            wrap: true,
            sortable: true
        },
        {
            name: 'Mounting Plate Option',
            selector: (row:PartnerCreditCardReader) => row.mounting_plate_option ?? "",
        },
    ];

    const ppeVendingMobileColumns:TableColumn<PartnerPpeVendingMobile>[] = [
        {
            name: 'Index',
            selector: (row:PartnerPpeVendingMobile, rowIndex) => (rowIndex ?? 0) + 1,
            wrap: true,
            sortable: true
        },
        {
            name: 'Wim Board',
            selector: (row:PartnerPpeVendingMobile) => row.modem_configuration?.wim_board ?? "",
            cell: row => (row.modem_configuration?.wim_board ?? false).toString(),
            wrap: true,
            sortable: true
        },
        {
            name: 'Region',
            selector: (row:PartnerPpeVendingMobile) => row.modem_configuration?.region ?? "",
        },
        {
            name: 'Sim Card Option',
            selector: (row:PartnerPpeVendingMobile) => row.sim_card_option ?? "",
        },
    ];

    const noteColumns:TableColumn<PartnerNote>[] = [
        {
            name: 'Created At',
            selector: (row:PartnerNote) => row.created_epoch ?? "",
            wrap: true,
            cell: row => <Datetime epoch={row.created_epoch}/>,
            sortable: true
        },
        {
            name: 'Created By',
            selector: (row:PartnerNote) => row.created_by ?? "",
        },
        {
            name: 'Content',
            selector: (row:PartnerNote) => row.content ?? "",
        },
    ];


    return (
        <div>
            <h3>Order Details</h3>
            <div className={"alert alert-success"}>
                <h5>Credit Card Reader Combo Order</h5>
                <DataTable
                    columns={creditCardReaderCombosColumns}
                    data={props.data.credit_card_reader_combos ?? []}
                />
            </div>
            <div className={"alert alert-success"}>
                <h5>Vendcoin Modems Order</h5>
                <DataTable
                    columns={vendcoinModemsColumns}
                    data={props.data.vendcoin_modems ?? []}
                />
            </div>
            <div className={"alert alert-success"}>
                <h5>Credit Card Readers Order</h5>
                <DataTable
                    columns={creditCardReaderColumns}
                    data={props.data.credit_card_readers ?? []}
                />
            </div>
            <div className={"alert alert-success"}>
                <h5>Ppe Vending Mobiles Order</h5>
                <DataTable
                    columns={ppeVendingMobileColumns}
                    data={props.data.ppe_vending_mobiles ?? []}
                />
            </div>
            <div className={"alert alert-success"}>
                <h5>Note</h5>
                <DataTable
                    columns={noteColumns}
                    data={props.data.notes ?? []}
                />
            </div>
        </div>
    );
}

export default OrderDetails;
